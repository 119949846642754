import {customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import "./balance-statement.less"

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-accounting-balance-statement')
export class BalanceStatement {

    client;
    dialogService;
    flash;
    form;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    submit(print) {

        let data = this.form.formService.getValue();
        this._isSubmitting = true;

        this.data = null;
        this.dummyDataForTable = {};
        this.dummyDataForAssetTable = [];
        this.dummyDataForLiabilityTable = [];

        let workflowId = print ? 'accounting/generate-balance-print' : 'accounting/generate-balance';

        const processItems = (item,dataTable, mainId = '') => {
            dataTable.push({ ...item, mainId: mainId || item.id });
            if (item.positions) {
                item.positions.forEach(position => {
                    dataTable.push({ ...position, id: mainId || item.id });
                });
            }
            if (item.subItems) {
                item.subItems.forEach((subItem, index) => {
                    processItems(subItem,dataTable, `${mainId}-${index}`);
                });
            }
        }
        return this.workflowService.trigger(workflowId, null, data).then(data => {

            this.data = data[0].balanceStatement;
            this._isSubmitting = false;

            if (data[0].url) {
                window.location.href = data[0].url;
            }
            for (const [key, value] of Object.entries(this.data.assets)) {
                processItems(value,this.dummyDataForAssetTable, `${key}main-assets`);
            }
            for (const [key, value] of Object.entries(this.data.liabilities)) {
                processItems(value,this.dummyDataForLiabilityTable, `${key}main-liabilities`);
            }
            this.dummyDataForTable = {
                ...this.data,
                assets : this.dummyDataForAssetTable,
                liabilities : this.dummyDataForLiabilityTable,
            }
            return dummyDataForTable;
        });
    }

    open(title,btnIndex) {
        let id = title.mainId
        const element = document.getElementById(id);
        var elms = document.querySelectorAll(`[id='${id}']`);
        var theButton = document.getElementById(btnIndex);
        if (theButton.getAttribute("aria-expanded") == "false"){

            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('visible');
                elms[i].classList.remove('hiderow');

            }
            theButton.setAttribute("aria-expanded", "true");
        }else{
            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('hiderow');
                elms[i].classList.remove('visible');
            }
            theButton.setAttribute("aria-expanded", "false");
        }
    }


}
