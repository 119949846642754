import {customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {DialogService} from 'aurelia-dialog';
import {FlashService} from "../../flash/flash-service";
import {WorkflowService} from "../../workflow/workflow-service";
import "./pal-statement.less"

@inject(
    Client,
    DialogService,
    FlashService,
    WorkflowService
)
@customElement('sio-accounting-pal-statement')
export class PalStatement {

    client;
    dialogService;
    flash;

    constructor(client, dialogService, flash, workflowService) {
        this.client = client;
        this.dialogService = dialogService;
        this.flash = flash;
        this.workflowService = workflowService;

        this.submit = this.submit.bind(this);
    }

    submit(print) {

        let data = this.form.formService.getValue();
        this._isSubmitting = true;

        this.data = null;
        this.dummyDataForTable = [];
        let workflowId = print ? 'accounting/generate-pal-print' : 'accounting/generate-pal';
        return this.workflowService.trigger(workflowId, null, data).then(data => {
            this.data = data[0].palStatement;
            this._isSubmitting = false;

            if (data[0].url) {
                window.location.href = data[0].url;
            }
            for (const [key, value] of Object.entries(this.data.items)) {
                this.dummyDataForTable.push({...value,mainId:key+'main'})
                value.subItems?.map((subItem,index) =>{
                    this.dummyDataForTable.push({...subItem,mainId:index+key+'main'+'-subItem'})
                    subItem.positions?.map(position =>{
                        this.dummyDataForTable.push({...position,id:index+key+'main'+'-subItem'})
                    })
                })

                value.positions?.map(position =>{
                    this.dummyDataForTable.push({...position,id:key+'main'})
                })
            }
            return dummyDataForTable;
        });
    }

    open(title,btnIndex) {
        let id = title.mainId
        const element = document.getElementById(id);
        var elms = document.querySelectorAll(`[id='${id}']`);
        var theButton = document.getElementById(btnIndex);
        if (theButton.getAttribute("aria-expanded") == "false"){

            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('visible');
                elms[i].classList.remove('hiderow');

            }
            theButton.setAttribute("aria-expanded", "true");
        }else{
            for(var i = 0; i < elms.length; i++) {
                elms[i].classList.add('hiderow');
                elms[i].classList.remove('visible');
            }
            theButton.setAttribute("aria-expanded", "false");
        }
    }
}
